export class AllowedIp {
  id
  ip
  mask = 32
  type = 'http'

  reset () {
      this.ip = ''
      this.mask = 32
  }
}
