import AccountService from '@/services/account.service.js'
import { AllowedIp } from '@/models/AllowedIp.js'
import EventBus from '@/util/EventBus'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'

export default {
  name: 'IpsVerification',
  components: { HeaderTopDashboard },
  data () {
    return {
      allowedIp: new AllowedIp(),
      allowedIps: [],
      subNets: [],
      totalItems: 0,
      pageCount: 0,
      page: 1,
    }
  },
  mounted () {
    this.getIps()
    this.setSubnets()
  },
  methods: {
    setSubnets () {
      for (var i = 32; i >= 0; i--) {
        this.subNets.push({ text: '/' + i, value: i })
      }
    },
    saveIp () {
      AccountService.saveIp(this.allowedIp).then((data) => {
        if (data.error) {
          EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al crear la IP').toString())
        } else {
          this.reset()
          this.getIps()
          EventBus.$emit('showAlert', 'success', this.$t(data.message))
        }
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Hubo un error al crear la IP').toString())
      })
    },
    getIps () {
      EventBus.$emit('showLoading', true)
      AccountService.getIps().then((data) => {
        this.allowedIps = data.ips
        this.totalItems = this.allowedIps.length
      })
      .finally(() => {
        EventBus.$emit('showLoading', false)
      })
    },
    removeIp (allowedIp) {
      AccountService.removeIp(allowedIp).then((data) => {
        EventBus.$emit('showAlert', 'success', data.message)
        this.getIps()
      }, (err) => {
        EventBus.$emit('showAlert', 'danger', err.error)
      })
    },
    reset () {
      this.allowedIp.reset()
      this.$nextTick(() => {
        this.$refs.ipForm.reset()
      })
    },
  },
  computed: {
    headers () {
      return [
        { text: 'IP', align: 'start', value: 'ip' },
        { text: this.$t('Subred'), align: 'start', value: 'mask' },
        { text: this.$t('Acciones'), value: 'actions', sortable: false },
      ]
    },
  },
}

